import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
// import { Box } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { SectionWithObjects } from '../components/section'
import Container from '../components/container'
import Hero from '../components/hero'
import EventsList from '../components/events-list'
import NextStep from '../components/next-step'
import getTranslations from '../utils/getTranslations'
import flattenEvents from '../utils/flattenEvents'

const EventsPage = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { page, events, heroBackgroundImage, nextStepBackground } = data
  const { title, content, acf, polylang_translations: translations } = page
  const {
    text_kam_dal: textKamDal,
    popisek_tlacitka_kam_dal: popisekTlacitkaKamDal,
    odkaz_kam_dal: odkazKamDal,
  } = acf

  return (
    <Layout
      locale={locale}
      pageTranslationLinks={getTranslations(locale, translations)}
    >
      <SEO title={title} />

      <Hero
        title={title}
        html={content}
        backgroundImage={heroBackgroundImage}
        backgroundPosition="50% 70%"
      />

      {events && events.edges && (
        <SectionWithObjects>
          <Container>
            <EventsList events={flattenEvents(events)} />
          </Container>
        </SectionWithObjects>
      )}

      <NextStep
        title={textKamDal || ''}
        link={odkazKamDal}
        label={popisekTlacitkaKamDal}
        backgroundImage={nextStepBackground}
      />
    </Layout>
  )
}

EventsPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default EventsPage

export const pageQuery = graphql`
  query EventsPageQuery($key: String!) {
    page: wordpressPage(slug: { eq: $key }) {
      ...PageData
      ...PageTranslations
      acf {
        ...NextStep
      }
    }
    # events: allWordpressWpEvent(sort: { fields: menu_order, order: ASC }) {
    events: allWordpressWpEvent {
      edges {
        node {
          title
          acf {
            od
            do
            lokace
            odkaz
          }
        }
      }
    }
    heroBackgroundImage: file(relativePath: { eq: "pivo-v-ruce.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nextStepBackground: file(relativePath: { eq: "pivo-v-ruce.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
